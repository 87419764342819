.theme-chooser {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    width: 100px;
    height: 150px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.modal-title {
  @extend .brandfont-black;
  font-size: 26px;
}

.language-chooser {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  div.language,
  button.language {
    text-align: center;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    transform: scale(1, 1);
    color: #eaeaea;

    .theme_plainlight & {
      color: #333;
    }

    &.selected {
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:hover {
      transform: scale(1.1, 1.1);
    }
  }
  // img {
  //   width: 100px;
  //   height: 150px;
  //   margin: 5px;
  //   border-radius: 5px;
  //   cursor: pointer;
  // }
}

.global-upload-progress {
  // position: ;
}

.top-right-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 9999;
}

.mustang {
  background-image: url("/assets/images/mustangmicro-bg.jpg");
}

.mustang,
.register-custom-ui {
  background-size: cover;
  background-position: bottom right;
  border-radius: 20px;

  #hw_serial_number,
  #hw_purchase_date {
    background-color: rgba(0, 0, 0, 0.45) !important;

    &.mustang-input,
    &.register-custom-ui-input {
      box-shadow: 0 0 0 1px var(--brand-blue);
      color: white;
    }
  }
}
