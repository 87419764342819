.hero-bg {
  position: absolute;
  width: 100%;
  background-color: #f5f5f5;
  top: 0px;
  left: 0px;
}

.cloud-nav-positioner {
  background-color: rgba(0, 0, 0, 0.6);
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  overflow: auto;
  .theme_plainlight & {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.notifications-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999;

  .notifications-container {
    width: 100%;
    max-width: 400px;
    position: absolute;
    max-height: 800px;
    overflow: auto;
    top: 60px;
    right: 10px;
    background-color: #282b2d;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 15px;

    .theme_plainlight & {
      background-color: #f5f5f5;
    }

    &.animate {
      transition: all 0.1s ease-in-out;
      animation: slide-down 0.4s ease;
    }
  }
}

.cloud-nav-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999;

  .close-btn {
    position: absolute;
    right: -15px;
    top: -30px;
  }

  .logout {
    font-size: 13px;
    cursor: pointer;
  }
}
.header-drop-menu {
  position: relative;
  width: 100%;

  .nav-item {
    margin-bottom: 10px;
  }

  &.animate {
    transition: all 0.1s ease-in-out;
    animation: slide-down 0.4s ease;
  }

  .header-menu-profile-pic {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-right: 10px;
  }

  .dropdown-item {
    margin: 5px 0px;
  }

  .hd-top {
    padding: 15px;

    .name {
      font-weight: bold;
      font-size: 18px;
      display: block;
    }

    .email {
      font-size: 15px;
      display: block;
    }
  }

  .mobile-nav-link {
    font-size: 14px;
    font-weight: bold;
    color: gray;
    padding: 5px;
    margin: 5px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    transition: all 0.1s ease-in-out;
    transform: scale(1, 1);
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: var(--brand-blue);
      background-color: transparent !important;
    }
  }
}

.global-header-container {
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 1049;
}

.global-header {
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 5px;
  border-radius: 20px;
  color: #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
  position: relative;

  .file-upload-progress {
    position: absolute;
    height: 26px;
    background-color: #225497;
    border-radius: 20px;
    left: -15px;
  }

  .file-upload-details {
    position: relative;
    font-size: 14px;
    font-weight: bold;
  }

  @media screen and (max-width: $break-fullwidth) {
    margin-top: 0px;
    border-radius: 0px;
    display: none;
  }

  .language {
    font-size: 13px;
    font-weight: bold;
    align-content: center;
    display: flex;
    align-content: center;
    cursor: pointer;
  }

  a {
    font-size: 13px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.5);
    padding: 2px 15px 2px 0px;

    &:hover {
      color: #41b9f5;
      text-decoration: none;
    }
  }
}

.account-link {
  display: block;
  font-size: 16px;
  transition: all 0.1s ease-in-out;
  font-weight: bold;
  cursor: pointer;
  padding: 0px 0px;
  @extend .brandfont-bold;
  color: #eaeaea;

  .theme_plainlight & {
    color: #333;
  }

  &:hover {
    color: var(--brand-blue);
    margin-left: 5px;
    background-color: transparent !important;
  }

  &.logout {
    color: red;
    font-size: 12px;

    &:hover {
      color: red;
    }
  }
}

.extra {
  display: none;
  @media screen and (max-width: 1190px) {
    display: block;
  }
}

.mobile-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  top: 0px;
  left: 0px;
  z-index: 9999;
  color: #eaeaea;
  text-align: center;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  .account-link {
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    margin: 10px 20px;
  }

  .theme_plainlight & {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .profile-pic {
    width: 50%;
    border-radius: 70px;
    max-width: 140px;
    margin: 20px auto 10px auto;
    display: block;
  }

  .name {
    font-size: 20px;
  }

  .email {
    font-size: 15px;
    opacity: 0.7;
  }
}

exc-exchange-header {
  .header-content.show-bg {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 30px;
  }
}

.prs-global-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
  position: relative;

  .mobile & {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1039;
    background-color: #1a1b1b;
  }
  .nav-overlay-open & {
    filter: blur(30px);
  }

  .theme_plainlight & {
    background-color: #f5f5f5;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  }

  .theme_plaindark & {
    // background-color: #20303c;
  }

  .file-upload-progress {
    position: absolute;
    left: 0px;
    height: 100%;
    background-color: #3a672c;
    z-index: 99;
  }

  .header-container {
    // max-width: 2200px;
    display: flex;
    height: 70px;
    padding: 0px 100px;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 10px;
    z-index: 999;
    position: relative;
    margin: auto;
    @extend .brandfont-bold;

    @media screen and (max-width: 780px) {
      padding: 0px 50px;
    }

    @media screen and (max-width: 600px) {
      padding: 0px 20px;
    }

    @media screen and (max-width: 400px) {
      padding: 0px 15px;
    }

    .connection-requests-badge {
      position: absolute;
      padding: 2px 7px;
      font-size: 10px;
      border-radius: 20px;
      background-color: $pink;
      border: 1px solid white;
      color: #eaeaea;
      font-weight: bold;
      top: -4px;
      left: -11px;
    }

    .unread-messages-badge {
      position: absolute;
      padding: 1px 5px;
      font-size: 8px;
      border-radius: 20px;
      background-color: $pink;
      border: 1px solid white;
      color: #eaeaea;
      font-weight: bold;
      top: -6px;
      right: -10px;
    }

    .logo-container {
      display: flex;
      align-items: center;
      text-decoration: none;
      height: 100%;

      .text-logo {
        transition: all 0.1s ease-in-out;
        width: 160px;

        &:hover {
          transform: scale(1.01, 1.01);
        }
      }

      .active-subscription & {
        color: #6692b2;
      }

      img.logo {
        width: 40px;
        height: 40px;
        margin-right: 10px;

        @media screen and (max-width: 600px) {
          height: 20px;
          width: 20px;
        }

        @media screen and (max-width: 420px) {
          height: 40px;
          width: 40px;
        }
      }
    }
    .options {
      flex: 1;
      margin-left: 10px;
      @media screen and (max-width: 1190px) {
        display: none;
      }

      .nav-option {
        font-size: 15px;
        margin: 0px 16px;
        color: #eaeaea;
        text-decoration: none;
        font-weight: bold;
        position: relative;
        transition: all 0.1s ease-in-out;

        &:hover {
          color: var(--brand-blue) !important;
        }

        .theme_plainlight & {
          color: #333;
        }

        .beta-flag {
          position: absolute;
          font-size: 8px;
          white-space: nowrap;
          font-family: Helvetica;
          letter-spacing: normal;
          left: -7px;
          top: -13px;
          background-color: green;
          padding: 0px 4px;
          border-radius: 10px;
          transform: rotate(-5deg);
        }
      }

      li {
        &.active {
          .nav-option {
            color: var(--brand-blue);
          }
        }
      }
    }

    .account {
      text-align: right;

      .profile-photo {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        transition: all 0.1s ease-in-out;

        &:hover {
          transform: scale(1.05, 1.05);
        }
        // box-shadow: 0px 0px 5px black;
      }
    }
  }
}

.share-page-header {
  display: flex;
  align-items: center;
  margin-top: -10px;
  height: 110px;

  @media screen and (max-width: 900px) {
    margin-bottom: 30px;
  }

  .logo-container {
    display: flex;
    align-items: center;
    text-decoration: none;

    display: none;
    @media screen and (max-width: 900px) {
    }

    span {
      color: #6692b2;
      @extend .brandfont-bold;
      font-size: 14px;
      white-space: nowrap;
      letter-spacing: 5px !important;

      @media screen and (max-width: 1180px) {
        font-size: 13px;
        white-space: wrap;
      }

      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    img.logo {
      height: 20px;
      margin-right: 10px;
    }
  }

  .file-header {
    flex: 1;
    min-width: 0;
    text-align: left;

    h3 {
      font-size: 20px;
      margin-bottom: 5px;
    }

    @media screen and (max-width: 500px) {
      font-size: 16px;
    }

    h3 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .shared-by {
      display: flex;
      justify-content: left;
      font-size: 12px;
      align-items: center;
      font-weight: bold;
    }

    img {
      width: 15px;
      height: 15px;
      border-radius: 10px;
      margin-right: 10px;
    }
  }

  .file-actions {
    width: 180px;
    text-align: right;
    @media screen and (max-width: 900px) {
      width: 110px;
    }
  }
}

.containerx {
  display: flex;
  max-width: 1000px;
  margin: auto;
}
.containerx div {
  height: 100px;
}
.container__left {
  background-color: red;
  width: 60px;
  flex: 0 0 auto;
}
.container__center {
  background-color: green;
  min-width: 0;
  flex: 1;
}
.container__right {
  background-color: blue;
  max-width: 200px;
  flex: 0 0 auto;
}

ul {
  &.nav {
    li.active {
      .account-link {
        color: var(--brand-blue);
      }
    }
  }
}

.theme-selection {
  legend {
    font-size: inherit;
  }
  font-size: inherit;
}

.uploading-header-details {
  font-size: 12px;
  display: block;
}

.skip-link {
  background-color: var(--brand-blue) !important;
  color: white !important;
  border-radius: 0px 0px 15px 15px;
  font-weight: 700;
  left: 50%;
  padding: 4px;
  position: absolute;
  transform: translateY(-100%);
}

.skip-link:focus-within {
  transform: translateY(0%);
}
