.sphere-color-bg {
  background-color: #1b8efc !important;
  background: #1b8efc !important;
  background-attachment: fixed;
  color: white !important;
}

.s1blur-bg {
  background-image: url("https://mypresonus.s3.us-east-1.amazonaws.com/images/presonus-so5-bg_dark.jpg");
  background-size: cover;
  background-position: center;
}

.s16blur-bg {
  background-image: url("https://mypresonus.s3.us-east-1.amazonaws.com/images/textured-gray-gradient.jpg");
  background-size: cover;
  background-position: center;
}

.container {
  width: 100% !important;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto !important;
  margin-left: auto !important;
}

.sphere-app-container {
  height: 100%;
  // max-width: 1800px;
  // margin: auto;
}

.sphere-app-content {
  height: calc(100vh - 71px);

  &.header-hidden {
    height: auto;
  }

  .mobile & {
    height: 100%;
    padding-top: 71px !important;
  }

  .mobile.full-page & {
    height: 100%;
    padding-top: 0px !important;
  }

  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  position: relative;

  .theme_plainlight & {
    background-color: #f5f5f5;
  }

  @media screen and (max-width: 500px) {
    margin: 0px;
  }

  .nav-overlay-open &,
  .app-modal-open & {
    transition: all 0.1s ease-out;
    filter: blur(20px);
  }

  .audio-playing & {
    height: calc(100vh - 121px);
  }

  .full-page & {
    height: 100% !important;
    overflow: visible !important;
  }

  // this breaks post detail overlay and messages detail on phones.
  // @media screen and (max-width: 600px) {
  //   height: auto;
  // }

  .fullwidth-layout-container {
    // max-width: 2200px;
    padding: 0px 100px;
    margin: auto;
    min-height: 100%;

    .theme_plainlight & {
      background-color: #eaeaea;
    }

    @media screen and (max-width: 780px) {
      padding: 0px 50px;
    }

    @media screen and (max-width: 600px) {
      padding: 0px 20px;
    }

    @media screen and (max-width: 400px) {
      padding: 0px 15px;
    }
  }
}

.zendesk .sphere-app-content {
  height: 100% !important;
}

.column-layout-container {
  display: flex;
  height: 100%;
  // max-width: 1800px;
  transition: all 0.1s ease-out;
  // max-width: 2200px;
  margin: auto;

  .mobile-back-btn {
    display: none;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 18px;
    @extend .brandfont-bold;

    @media screen and (max-width: 680px) {
      display: block;
    }
  }

  .menu {
    width: 350px;
    height: 100%;
    overflow: auto;
    border-right: 1px solid rgba(255, 255, 255, 0.04);

    padding: 0px 0px 15px 100px;

    @media screen and (max-width: 780px) {
      padding: 5px 0px 15px 50px;
      width: 315px;
    }

    @media screen and (max-width: 600px) {
      padding: 5px 0px 15px 20px;
    }

    @media screen and (max-width: 400px) {
      padding: 5px 0px 15px 15px;
    }

    .theme_plainlight & {
      border-right: 1px solid rgba(0, 0, 0, 0.04);
      background-color: #f5f5f5;
    }

    .menu-top {
      margin-bottom: 10px;
      padding-top: 30px;
    }

    .menu-title {
      line-height: 40px;
      font-size: 1.5rem;
      font-family: "Helvetica Now Bold";
      @extend .brandfont-bold;
    }

    @media screen and (max-width: 680px) {
      display: none;
      padding: 5px 0px 15px 15px;

      &.mobile-shown {
        width: 100%;
        display: block;
      }
    }

    hr {
      border-top: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      margin-top: 10px;
      margin-bottom: 10px;

      .theme_plainlight & {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .menu-search {
      width: 100%;
      background-color: #232323;
      outline: none;
      border-radius: 18px;
      padding: 5px;
      border: none;
      margin: 10px 0px;
      &::placeholder {
        color: rgb(177, 177, 177);
      }

      &.top {
        margin: 10px;
      }

      .theme_plainlight & {
        background-color: #eaeaea;
        color: #333;
      }
    }

    .menu-option-header {
      font-size: 16px;
      list-style: none;
      margin: 10px 0px 0px -15px;
      padding: 5px 15px 5px 15px;
      // background-color: rgba(255, 255, 255, 0.1);
      @extend .brandfont-black;

      .theme_plainlight & {
        // background-color: rgba(0, 0, 0, 0.1);
      }
    }

    &.mobile-shown {
      .menu-options {
        width: 100%;
        display: block !important;
      }
    }

    .menu-options {
      padding: 0px;
      margin: 0px;

      .menu-title {
        line-height: 40px;
        font-size: 1.5rem;
        font-family: "Helvetica Now Bold";
        margin-bottom: 0px;
        @extend .brandfont-bold;
      }

      @media screen and (max-width: 680px) {
        display: none;
        padding: 5px 0px 15px 15px;
      }

      hr {
        border-top: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        margin-top: 10px;
        margin-bottom: 10px;

        .theme_plainlight & {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }

      .menu-search {
        width: 100%;
        background-color: #232323;
        outline: none;
        border-radius: 18px;
        padding: 5px;
        border: none;
        margin: 10px 0px;
        &::placeholder {
          color: rgb(177, 177, 177);
        }

        &.top {
          margin: 10px;
        }

        .theme_plainlight & {
          background-color: #eaeaea;
          color: #333;
        }
      }

      ul {
        padding: 0px;
        margin: 0px;

        li.menu-option-header {
          font-size: 16px;
          list-style: none;
          margin: 10px 0px 0px -15px;
          padding: 5px 15px 5px 15px;
          // background-color: rgba(255, 255, 255, 0.1);
          @extend .brandfont-black;

          .theme_plainlight & {
            // background-color: rgba(0, 0, 0, 0.1);
          }
        }

        li.menu-option {
          list-style: none;
          cursor: pointer;
          overflow: hidden;

          .primary-option {
            padding: 10px 10px 10px 10px;
            font-size: 15px;
            width: 100%;
            display: flex;
            position: relative;
            justify-content: space-between;
            font-weight: bold;
            transition: all 0.1s ease-out;
            @extend .brandfont-bold;
            color: inherit;
            text-decoration: none;

            .primary-option-title,
            .title {
              font-family: "Helvetica Now Bold";
              max-width: 100%;
              overflow: hidden;
            }

            .only-on-hover {
              opacity: 0;
            }

            &:hover {
              .only-on-hover {
                opacity: 1;
              }

              .primary-option-title {
                max-width: calc(100% - 40px);
              }
            }

            .unread-messages-badge {
              // position: absolute;
              padding: 2px 7px;
              margin-left: 5px;
              font-size: 10px;
              border-radius: 20px;
              background-color: red;
              border: 1px solid white;
              color: #eaeaea;
              font-weight: bold;
              // right: 10px;
            }

            &:hover {
              margin-left: 5px;

              .theme_plainlight & {
                // background-color: #f5f5f5;
              }
            }
          }

          &.active {
            .primary-option {
              color: var(--brand-blue);
              border-bottom: 2px solid var(--brand-blue);

              .title {
                @extend .brandfont-black;
              }
            }
          }

          &.archived {
            opacity: 0.3;
          }

          .menu-suboptions {
            margin: 0px 0px 0px -15px;
            padding: 5px 15px 5px 40px;
            .sub-option {
              border-bottom: 1px solid transparent;
              margin-left: 10px;
              font-size: 13px;
              transition: all 0.1s ease-out;

              a {
                padding: 5px 0px;
                display: flex;
                justify-content: space-between;
                color: inherit;
                text-decoration: none;
                align-items: center;
              }

              .tiny-pill {
                padding: 2px;
                font-size: 10px;
              }

              img.side-image {
                width: 50px;
                height: 50px;
                padding: 2px;
              }

              img.icon {
                background-color: #333;
                border-radius: 12px;
                padding: 2px;
                width: 25px;
                height: 25px;
              }

              &:hover {
                margin-left: 15px;
              }

              .active {
                color: var(--brand-blue);

                .theme_plainlight & {
                  color: var(--brand-blue);
                }
                font-weight: bold;
                img.icon {
                  background-color: var(--brand-blue);
                }
              }

              .archived {
                opacity: 0.6;
              }
            }
          }
        }

        .list-item-badge {
          font-size: 12px;
          // background-color: var(--brand-blue);;
          min-width: 20px;
          height: 20px;
          padding: 0px 5px;
          // color: #eaeaea;
          border-radius: 10px;
          // border: 1px solid white;
          text-align: center;
          line-height: 19px;
          font-weight: bold;
          align-self: center;
        }

        li.menu-option.detailed {
          display: flex;
          justify-content: space-between;
          // border-bottom: 1px solid rgba(255, 255, 255, 0.05);

          .primary-option {
            padding: 15px 10px 15px 15px;
          }

          img {
            width: 40px;
            align-self: center;
            margin-right: 10px;

            &.align-top {
              align-self: flex-start;
            }

            &.viewing {
              border: 2px solid #00f600 !important;
            }
          }

          .extra-info {
            display: block;
            line-height: 17px;
            font-size: 13px;
          }

          .msg-extra-info {
            display: block;
            line-height: 17px;
            font-size: 13px;
            color: gray;
          }
        }
      }

      nav h2.menu-option-header {
        font-size: 1rem;
        margin-top: 18px;
        margin-bottom: 7px;
        @extend .brandfont-black;

        .theme_plainlight & {
          // background-color: rgba(0, 0, 0, 0.1);
        }
      }

      li.menu-option {
        list-style: none;
        cursor: pointer;
        overflow: hidden;

        .primary-option {
          padding: 10px 10px 10px 10px;
          font-size: 15px;
          width: 100%;
          display: flex;
          position: relative;
          justify-content: space-between;
          font-weight: bold;
          transition: all 0.1s ease-out;
          @extend .brandfont-bold;
          color: inherit;
          text-decoration: none;

          .primary-option-title,
          .title {
            font-family: "Helvetica Now Bold";
            max-width: 100%;
            overflow: hidden;
          }

          .only-on-hover {
            opacity: 0;
          }

          &:hover {
            .only-on-hover {
              opacity: 1;
            }

            .primary-option-title {
              max-width: calc(100% - 40px);
            }
          }

          .unread-messages-badge {
            // position: absolute;
            padding: 2px 7px;
            margin-left: 5px;
            font-size: 10px;
            border-radius: 20px;
            background-color: red;
            border: 1px solid white;
            color: #eaeaea;
            font-weight: bold;
            // right: 10px;
          }

          &:hover {
            margin-left: 5px;

            .theme_plainlight & {
              // background-color: #f5f5f5;
            }
          }
        }

        &.active {
          .primary-option {
            color: var(--brand-blue);
            border-bottom: 2px solid var(--brand-blue);

            .title {
              @extend .brandfont-black;
            }
          }
        }

        &.archived {
          opacity: 0.3;
        }

        .menu-suboptions {
          margin: 0px 0px 0px -15px;
          padding: 5px 15px 5px 40px;
          .sub-option {
            border-bottom: 1px solid transparent;
            margin-left: 10px;
            font-size: 13px;
            transition: all 0.1s ease-out;

            a {
              padding: 5px 0px;
              display: flex;
              justify-content: space-between;
              color: inherit;
              text-decoration: none;
              align-items: center;
            }

            .tiny-pill {
              padding: 2px;
              font-size: 10px;
            }

            img.side-image {
              width: 50px;
              height: 50px;
              padding: 2px;
            }

            img.icon {
              background-color: #333;
              border-radius: 12px;
              padding: 2px;
              width: 25px;
              height: 25px;
            }

            &:hover {
              margin-left: 15px;
            }

            .active {
              color: var(--brand-blue);

              .theme_plainlight & {
                color: var(--brand-blue);
              }
              font-weight: bold;
              img.icon {
                background-color: var(--brand-blue);
              }
            }

            .archived {
              opacity: 0.6;
            }
          }
        }
      }

      .list-item-badge {
        font-size: 12px;
        // background-color: var(--brand-blue);;
        min-width: 20px;
        height: 20px;
        padding: 0px 5px;
        // color: #eaeaea;
        border-radius: 10px;
        // border: 1px solid white;
        text-align: center;
        line-height: 19px;
        font-weight: bold;
        align-self: center;
      }

      li.menu-option.detailed {
        display: flex;
        justify-content: space-between;
        // border-bottom: 1px solid rgba(255, 255, 255, 0.05);

        .primary-option {
          padding: 15px 10px 15px 15px;
        }

        img {
          width: 40px;
          align-self: center;
          margin-right: 10px;

          &.align-top {
            align-self: flex-start;
          }

          &.viewing {
            border: 2px solid #00f600 !important;
          }
        }

        .extra-info {
          display: block;
          line-height: 17px;
          font-size: 13px;
        }

        .msg-extra-info {
          display: block;
          line-height: 16px;
          font-size: 13px;
          color: gray;
          max-height: 34px;
          overflow: hidden;
        }
        .msg-extra-info-date {
          display: block;
          font-size: 11px;
          color: gray;
          font-weight: normal;
        }
      }
    }

    nav h5.menu-option-header {
      font-size: 1rem;
      margin-top: 18px;
      margin-bottom: 7px;
      @extend .brandfont-black;

      .theme_plainlight & {
        // background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .content {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin: auto;
    padding: 30px 100px 0px 60px;

    .theme_plainlight & {
      background-color: #eaeaea;
    }

    @media screen and (max-width: 1200px) {
      padding: 30px 50px 0px 20px;
    }

    @media screen and (max-width: 680px) {
      padding: 10px 20px 0px 20px;
    }

    @media screen and (max-width: 400px) {
      padding: 10px 15px 0px 15px;
    }

    .content-constrained {
      max-width: 1800px;
      margin-left: auto;
      margin-right: auto;
      // height: 100%;

      @media screen and (max-width: 600px) {
        height: auto;
      }
    }

    .content-title {
      line-height: 40px;
      display: flex;
      font-size: 1.5rem;
      align-items: center;
      @extend .brandfont-bold;

      &.center {
        justify-content: center;
      }
    }

    @media screen and (max-width: 600px) {
      display: none;

      &.mobile-shown {
        display: block;
      }
    }

    &.fixed {
      overflow: hidden;
    }
  }
}

.full-page.theme_plainlight {
  .sphere-app-content {
    background-color: transparent;
  }
}

.container,
.container-fluid {
  max-width: 1800px;
}

.sphere-bright-bg {
  background: $sphere-bright-gradient;
}

.cloud-app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &.full-page {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  @media screen and (max-width: 600px) {
    width: auto;
    height: auto;
    min-height: 100%;
    overflow: auto;
  }

  .profile-home {
    .profile-photo {
      img {
        width: 80%;
        border-radius: 100%;
        max-width: 200px;
        border: 3px solid var(--brand-blue);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
      }
    }
    .user-data {
      display: flex;
      span {
        margin: 0px 10px;
        font-size: 12px;
        padding: 2px 4px;
        border: 1px solid white;
        border-radius: 4px;
      }
    }
  }

  .hover-zoom {
    transition: all 0.1s ease-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.02) !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .title {
    font-family: $main-font;
    text-transform: uppercase;
  }

  .sphere-login-splash {
    width: 100%;
    margin-bottom: -100px;
    margin-top: -100px;
    position: relative;

    .title {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      h2 {
        letter-spacing: 15px;
        background: -webkit-linear-gradient(#fff, #656261);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    img {
      width: 100%;
      max-width: 500px;
      margin: auto;
    }
  }

  .page-nav-header {
    &.no-border {
      border-bottom: 0px solid transparent;
      margin-bottom: 0px;
    }

    &.no-pills {
      @media screen and (max-width: $break-small) {
        flex-direction: row;
      }
    }

    @media screen and (max-width: $break-small) {
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px;
      padding-bottom: 0px;

      .back-container {
        justify-content: center;
      }

      .title-pills-container {
        display: block;
      }
      h4 {
        display: block;
        text-align: center;
        margin-bottom: 10px;
      }

      .nav-pills {
        margin-left: 0px;
        flex-direction: row;
        margin: 10px 10px 0px 10px;
        justify-content: center;

        .nav-item {
          margin-bottom: 10px;
        }
      }

      .buttons {
        margin-bottom: 10px;
      }

      fa-icon.back-btn {
        margin-bottom: 10px;
      }
    }
  }

  input::placeholder {
    color: lightgray;
  }

  .content-container {
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(29, 29, 29);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    margin-top: 30px;
  }

  .wrapper {
    padding-bottom: 100px;
    min-height: 100%;
  }

  .home-container {
    &.hideMe {
      opacity: 0;
    }

    &.start-position {
      opacity: 0;
      transform: translate(0px, 50px);
    }

    &.end-position {
      transition: all 3s ease-in-out;
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  .cloud-header {
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    margin: 0px auto;
    text-align: center;

    img.logo {
      width: 150px;
      height: 58px;
      margin: 0px 20px;
    }

    fa-icon {
      font-size: 22px;
    }

    .header-menu-link {
      background: $cloud-selected;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0px 0px 8px #fafad278;
    }

    img.header-profile-pic {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      cursor: pointer;
    }

    &.start-position {
      .user-nav,
      .header-profile-pic,
      img.logo {
        opacity: 0;
        transform: translate(0px, 50px);
      }

      // transition: all 2s ease-in-out;
      // opacity: 0;
      // transform: translate(0px, 50px);
    }

    &.end-position {
      transition: all 3s ease-in-out;

      .user-nav,
      .header-profile-pic,
      img.logo {
        transition: all 3s ease-in-out;
        opacity: 1;
        transform: translate(0px, 0px);
      }
    }
  }

  .home-profile-section {
    margin-bottom: 50px;
    position: relative;
    min-height: 200px;

    .top-info {
      background-size: cover;
      background-position: top center;
      border-radius: 20px;

      .more-about-you {
        font-size: 13px;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }
    }

    .cover-photo-container {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 200px;
      background-size: cover;
      background-position: top center;
      border-radius: 20px 20px 0px 0px;
    }

    .profile-photo {
      width: 150px;
      border-radius: 750px;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
  .login-container {
    .below-title {
      letter-spacing: 15px;
      background: -webkit-linear-gradient(#fff, #656261);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 20px 0px;
    }

    .form {
      max-width: 400px;
      margin: auto;
    }

    input {
      padding: 15px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.1);
      display: block;
      width: 100%;
      border: none;
      margin: 10px 0px;
      color: #eaeaea;
      outline: none;

      .theme_plainlight & {
        background-color: #eaeaea;
      }
    }
  }
}

.metadata-item {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 10px 10px 0px 0px;
  display: inline-block;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 20px;

  .title {
    text-transform: uppercase;
    color: lightgray;
  }

  .value {
    margin-left: 5px;
    font-weight: bold;
  }
}

.chat-container {
  // height: 100%;
  background-color: #232323;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  .theme_plainlight & {
    background-color: #eaeaea;
  }

  &.comments {
    background-color: transparent;
  }

  .atting-collabs {
    width: auto;
    position: absolute;
    bottom: 50px;
    left: 10px;
    background-color: rgb(138, 138, 138);
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    overflow: hidden;

    div {
      padding: 4px;

      &.selected {
        background-color: var(--brand-blue);
        color: #eaeaea;
      }
    }
  }

  .users-typing {
    z-index: 99998;
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
    margin-bottom: 5px;
    transform: translate(0px, 30px);

    .typing-user {
      padding-right: 5px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.4);
    }

    &.visible {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  .messages {
    padding: 50px 10px;
    overflow: auto;
  }

  .input-container {
    background-color: #232323;
    position: absolute;
    bottom: 0px;
    width: 100%;

    .theme_plainlight & {
      background-color: #eaeaea;
    }

    .force-dark & {
      background-color: #232323 !important;
    }

    .comment-label {
      font-size: 12px;
      padding-left: 10px;
      left: 5px;

      .force-dark & {
        color: #eaeaea;
      }
    }
  }
}

.discussion-container,
.files-container {
  padding: 50px 0px 10px 0px;
  overflow: auto;
  background-color: #232323;
  border-radius: 10px;

  .theme_plainlight & {
    background-color: #f5f5f5;
  }

  &.no-bg {
    background-color: transparent !important;
  }
}

.file-folders-container {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #232323;
  border-radius: 10px 10px 0px 0px;
  padding: 5px 5px 60px 5px;
  height: 100%;

  .theme_plainlight & {
    background-color: #f5f5f5;
  }
}

.hovering {
  .file-folders-container {
    background-color: rgba(0, 128, 0, 0.548);
  }
}

.discussion-container,
.files-container,
.chat-container {
  &.archived {
    opacity: 0.2;
    pointer-events: none;
  }

  .msg-container {
    display: flex;
    margin-bottom: 20px;

    img {
      max-width: 100%;
    }

    .text {
      padding: 5px;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.2);
      display: inline-block;
      font-size: 16px;
      max-width: 600px;

      .theme_plainlight & {
        background-color: #d8d8d8;
      }
    }

    .attached-file {
      .data {
        display: flex;
        align-items: center;

        .metadata {
          display: flex;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 5px;
          padding: 5px;
          margin-top: 5px;
          font-size: 13px;
          align-items: center;
          fa-icon {
            font-size: 22px;
            margin-left: 10px;
          }
        }
      }
    }

    &.them {
      .msg {
        margin-left: 10px;
        margin-bottom: 10px;
      }
      .text {
        margin-right: 40px;
      }
    }

    &.me {
      flex-direction: row-reverse;
      text-align: right;

      .msg {
        margin-right: 10px;
        margin-bottom: 10px;
      }

      .text {
        background: var(--brand-blue);
        color: #eaeaea;
        margin-left: 40px;

        a {
          color: #00f6f6 !important;
        }
      }

      .data {
        justify-content: flex-end;
      }
    }

    .timestamp {
      font-size: 12px;
    }
  }

  &.comments {
    .me {
      flex-direction: initial;
      text-align: left;
    }

    .msg {
      flex: 1;
    }

    // .text {
    //   background-color: transparent;
    // }

    .msg-container {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      flex-wrap: wrap;
    }

    .messages {
      padding: 10px 10px 65px 10px;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 10px;
    }
  }

  .msg-list-download-btn {
    cursor: pointer;
  }

  .msg-options-link {
    color: var(--brand-blue);
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    background: transparent;
    border: none;
    padding: 0px;
  }

  .profile-photo {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    flex-shrink: 0;
  }
}

.reply-input {
  // background-color: #2e2e2e;
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .theme_plainlight & {
    // background-color: #e9e9e9;
  }

  &.hovering {
    background-color: rgba(0, 128, 0, 0.548);
  }

  textarea,
  input {
    padding: 5px;
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    color: #eaeaea;
  }
}

.send-btn {
  margin: 0px 10px;
  font-weight: bold;
  color: #eaeaea;
  white-space: nowrap;
  cursor: pointer;
  background-color: var(--brand-blue);
  padding: 5px 10px;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
  font-size: 14px;

  &.no-margin {
    margin: 0px 0px;
  }

  &.disabled {
    background-color: gray;
  }

  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.7);
    transform: scale(1.03) !important;
  }
}

.msg-input {
  width: 100%;
  background-color: #232323;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .theme_plainlight & {
    background-color: #c6c6c6;
  }

  textarea,
  input {
    padding: 10px;
    background-color: transparent;
    outline: none;
    border: none;
    color: #eaeaea;

    .theme_plainlight & {
      color: #333;
    }
  }

  .send-btn-container {
    position: absolute;
    right: 0px;
    bottom: 3px;
    z-index: 9999;
    align-items: center;
  }

  .attach-btn {
    font-size: 20px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 10px;
  }

  &.fixed-bottom {
    position: fixed;
    // bottom: 25px;
    width: calc(100% - 360px);
    left: 354px;

    @media screen and (max-width: 780px) {
      left: 320px;
      width: calc(100% - 325px);
    }

    @media screen and (max-width: 680px) {
      left: 5px;
      width: calc(100% - 10px);
    }
  }

  .workspace-message-input {
    position: fixed;
    bottom: 25px;
    width: calc(100% - 315px);
    left: 310px;
  }
}

.workspace-title {
  flex: 1 0 calc(100% - 125px);
  text-overflow: ellipses;
  white-space: nowrap;
  overflow: hidden;

  h1 {
    margin-bottom: 0px;
    font-size: 1.5rem;
    @extend .brandfont-bold;
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
}

.channel-title {
  flex: 1 0 calc(100% - 125px);
  text-overflow: ellipses;
  white-space: nowrap;
  overflow: hidden;

  h4 {
    margin-bottom: 0px;
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
}

.workspace-action {
  flex: 0 1 90px;
  @media screen and (min-width: 600px) {
  }
}

.workspace-sidebar,
.filedetail-sidebar {
  @media screen and (min-width: $break-small) {
    height: calc(100vh - 190px);
    overflow: auto;
  }
  overflow: auto;

  #workspace-details {
    margin-top: 10px;
    font-size: 13px;

    #details-text {
      max-height: 60px;
      overflow: hidden;

      &.expanded {
        max-height: none;
      }
    }
  }

  .image-container {
    position: relative;

    .mainmix-container {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .mainmix-playbtn {
        width: 50%;
        height: 50%;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.8);
        border: 2px solid rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;

        fa-icon {
          font-size: 40px;
        }
      }
    }
  }

  .collaborators {
    .collab-status {
      font-size: 12px;
    }
  }
}

.workspace-sidebar {
  @media screen and (min-width: $break-small) {
    height: calc(100vh - 160px);
    overflow: auto;

    .audio-playing & {
      height: calc(100vh - 220px);
    }
  }
}
.filedetail-sidebar {
  height: calc(100vh - 176px);

  .audio-playing & {
    height: calc(100vh - 385px);
  }

  .sidebar-content {
    overflow: auto;
    height: 100%;
    padding-top: 51px;
  }

  @media screen and (min-width: $break-small) {
    height: calc(100vh - 133px);
    overflow: hidden;

    .audio-playing & {
      height: calc(100vh - 193px);
    }
  }

  &.isAudio {
    height: calc(100vh - 365px);

    .audio-playing & {
      height: calc(100vh - 385px);
    }

    @media screen and (min-width: $break-small) {
      height: calc(100vh - 341px);
      overflow: hidden;
    }

    .sidebar-content {
      height: 100%;

      @media screen and (min-width: $break-small) {
        height: calc(100% - 30px);
      }
    }
  }

  &.hasStems {
    height: calc(100vh - 410px);

    @media screen and (min-width: $break-small) {
      height: calc(100vh - 357px);
      overflow: hidden;
    }

    .sidebar-content {
      height: 100%;

      @media screen and (min-width: $break-small) {
        height: calc(100% - 30px);
      }
    }
  }
}
.workspace-page,
.satellite-page,
.messages-page {
  .msg-image-container {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    border-radius: 10px;
    margin: auto;
    width: 200px;
    height: 200px;
    background-color: #333;

    .theme_plainlight & {
      background-color: #eaeaea;
    }
  }
  .msg-image {
    max-height: 200px;
    max-width: 80%;
    cursor: pointer;
  }

  .timeline-drop-zone {
    border-radius: 10px 10px 0px 0px;
  }

  .timeline-drop-zone.hovering {
    background-color: rgba(0, 128, 0, 0.548);
  }

  .discussion-files-view {
    position: relative;
    height: 100%;

    .upload-file-btn-container {
      position: absolute;
      width: 100%;
      bottom: 10px;
    }

    .atting-collabs {
      width: auto;
      position: absolute;
      bottom: 10px;
      left: 10px;
      background-color: rgb(138, 138, 138);
      font-size: 14px;
      font-weight: bold;
      border-radius: 5px;
      overflow: hidden;

      div {
        padding: 4px;

        &.selected {
          background-color: var(--brand-blue);
          color: #eaeaea;
        }
      }
    }

    .users-typing {
      z-index: 99998;
      position: absolute;
      bottom: 0px;
      width: 100%;
      text-align: center;
      transition: all 0.2s ease-in-out;
      font-size: 14px;
      margin-bottom: 5px;
      transform: translate(0px, 30px);

      .typing-user {
        padding-right: 5px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.4);
      }

      &.visible {
        opacity: 1;
        transform: translate(0px, 0px);
      }
    }

    .files-note {
      position: absolute;
      top: 30px;
      border-radius: 20px;
      margin: 10px;
      z-index: 99;
      background-color: #333;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.5);
      padding: 10px;

      .theme_plainlight & {
        background-color: #eaeaea;
      }
    }

    .view-chooser-container {
      position: absolute;
      width: 300px;
      left: 50%;
      margin-left: -150px;
    }

    .timeline-instruction-overlay {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px;
    }
  }

  .upload-file-btn {
    width: 100%;
    background-color: #313131;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
    align-items: center;
    color: #eaeaea;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &:hover {
      background-color: var(--brand-blue);
      color: #eaeaea;
    }

    &.active {
      background-color: var(--brand-blue);
      color: #eaeaea;
    }
  }
}

.storage-details {
  display: flex;
}
.storage-meter,
.meter,
.meter-end {
  width: 100%;
  display: flex;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  overflow: hidden;

  .total-used {
    background-color: var(--brand-blue);

    &.warning {
      background-color: $brand-yellow;
    }

    &.danger {
      background-color: $brand-red;
    }
  }

  .theme_plainlight & {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.bundle-time-meter {
  position: relative;

  .meter-container {
    display: flex;
  }

  .start-end-note {
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 25px;

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .today-indicator {
    @extend .brandfont-bold;
    font-size: 12px;
  }

  .meter-end {
    width: 30px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.canceling {
      background-color: #ff666f;
    }
    &.scheduled {
      background-color: $pink;
    }
  }
}

.plan-detail-section {
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;

  .theme_plainlight & {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.fullscreen-bg {
  position: fixed;
  width: 100%;
  top: 0px;
  height: 100%;
  opacity: 0.3;
  filter: blur(20px);
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.pill {
  padding: 5px 10px;
  border-radius: 20px;
  color: #eaeaea;
  font-weight: bold;
  display: inline-block;
  margin: 2px 5px 2px 0px;

  &.sm {
    font-size: 11px !important;
    padding: 3px 7px;
  }

  &.green {
    background-color: green;
    border: 1px solid rgb(0, 94, 0);
  }

  &.orange {
    background-color: $orange;
    border: 1px solid rgb(165, 107, 0);
  }

  &.red {
    background-color: red;
    border: 1px solid rgb(110, 0, 0);
  }

  &.blue {
    background-color: var(--brand-blue);
    border: var(--brand-blue);
  }

  &.trans {
    background-color: rgba(255, 255, 255, 0.2);

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.2);
      color: #333;
    }

    &.force-dark {
      background-color: rgba(255, 255, 255, 0.2) !important;
      color: white !important;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.file-upload-indicator {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 300px;
  max-width: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
}

.warning-note,
.failed-note {
  color: #eaeaea;
  background-color: #a00000;
  padding: 3px 5px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 13px;
}

.warning-note {
  background-color: #a06d00;
}
.failed-note {
  background-color: #a00000;
}

.collab-user-status {
  display: inline-block;
  font-size: 13px;
  color: #eaeaea;
  background-color: rgb(77, 77, 77);
  padding: 3px 5px;
  border-radius: 10px;

  &.online {
    background-color: $green;
  }
}

.unread-count {
  display: inline-block;
  background-color: red;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
}

.text-muted {
  color: var(--brand-blue);
}

.gothic-btn {
  padding: 10px;
  color: #eaeaea;
  cursor: pointer;
  display: inline-block;
  @extend .brandfont-bold;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 10px;
  transition: all 0.1s ease-out;

  fa-icon {
    font-size: 14px;
  }

  &:hover {
    color: #eaeaea;
    text-decoration: none;
    letter-spacing: 15px;
  }
}

.events-list {
  a {
    text-decoration: none;
    color: inherit;
  }
}

.more-info-list {
  padding: 0px;
  margin: 0px;
  text-align: left;

  li {
    padding: 5px 10px;
    background-color: $transBG;
    margin: 0px 5px 10px 0px;
    display: inline-block;
    color: #eaeaea;
    border-radius: 20px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    .theme_plainlight & {
      background-color: black;
    }

    &.done {
      background-color: var(--brand-blue);
    }

    &:hover {
      transform: scale(1.05);
    }

    a {
      color: #eaeaea;
      &:hover {
        text-decoration: none;
      }

      &:focus-visible {
        padding: 5px 10px;
        border-radius: 20px;
        margin: 0px -7px;
      }
    }
  }
}

.warning-container {
  background-color: $red;
  color: #eaeaea;
  padding: 10px;
  border-radius: 10px;
}

.flexible-msg-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .flexible-row {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.msgs {
      background-color: red;
      flex: 0 1 100%;
      overflow: auto;
    }

    &.input {
      height: auto;
      background-color: green;
    }
  }
}

.event-detail {
  img {
    max-width: 100%;
  }
}

.signup-flow {
  input.form-control,
  textarea.form-control {
    background-color: #232323;
    border: none;
    color: #eaeaea;
  }

  input.form-control::placeholder,
  textarea.form-control::placeholder {
    color: #232323;
  }
}

.inline-audio-comment-list-item {
  padding-left: 5px;

  .comment-time {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 5px;
    padding-top: 5px;
    display: block;

    .theme_plainlight & {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
  }

  &.reply {
    padding-left: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.1);
    padding-left: 5px;
    border-radius: 5px;

    .comment-time {
      border-top: 1px solid transparent;
    }

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.fs-close-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 20px;
  z-index: 9999999;

  &.right {
    right: 10px;
    left: auto;
  }
}

.messages-page {
  .messages-container {
    // height: calc(100vh - 65px);
    overflow: hidden;

    @media screen and (max-width: 600px) {
      // height: calc(100vh - 118px);
    }
  }
}

.button-list {
  display: flex;
  justify-content: left;
  margin-bottom: 0;
  padding-left: 0px;
  li {
    list-style-type: none;
  }
}

.file-drop-zone {
  &.hovering {
    background-color: rgba(0, 128, 0, 0.548);
  }
}

.archived-notice {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  color: #efefef;
}

.font-h6 {
  font-size: 1rem;
}

.small-text {
  font-size: 80%;
  font-weight: 400;
}

myp-add-connection,
myp-create-ticket,
myp-switch-language,
myp-feature-updates,
myp-change-password,
myp-add-workspace,
myp-add-review,
myp-register-product,
myp-date-picker,
myp-register-success-splash {
  h1 {
    &.modal-title {
      font-size: 1.5rem;
    }
  }

  h6 {
    &.control-label {
      line-height: 1.5;
      font-weight: 400;
    }
  }
}
