.comment-container,
.reply-container {
  display: flex;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  position: relative;
  text-align: left;
  margin-top: 10px;
  padding: 10px;
  color: #eaeaea;

  .theme_plainlight & {
    color: #333;
    
  }

  &.force-dark {
    color: #eaeaea;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.comment-container,
.reply-container,
.community-post,
.edit-comment-form {
  .post-mention {
    color: var(--brand-blue);
    font-weight: bold;
    cursor: pointer;
  }

  .inline-mention {
    color: #eaeaea;
    background-color: var(--brand-blue);
    font-weight: bold;
    padding: 0px 8px;
    border-radius: 15px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
      border-radius: 10px;
      margin-right: 5px;
    }
  }

  .inline-article,
  .inline-video,
  .inline-lesson,
  .inline-share {
    font-weight: bold;
    color: var(--brand-blue);
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 3px 7px;
    border-radius: 10px;
  }

  .like-count {
    padding: 2px 5px;
    font-size: 10px;
    background-color: var(--brand-blue);
    color: #eaeaea;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
    margin-left: -10px;
  }

  a.author-name {
    color: #eaeaea;

    .theme_plainlight & {
      color: #333;
      &.force-dark {
        color: #eaeaea;
      }
    }
    text-decoration: none;
  }

  .flag-warning {
    position: absolute;
    top: 5px;
    right: 5px;
    color: red;
    font-size: 12px;
  }

  &.flagged {
    border: 2px solid red;
  }

  .text {
    padding: 0px 5px;
    background-color: transparent;
    display: inline-block;
    font-size: 15px;
  }

  .text {
    // background-color: transparent !important;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }

  .msg {
    flex: 1;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .timestamp {
    font-size: 11px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .msg-options-link {
    color: #707070;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    background: transparent;
    border: none;
    transition: all 0.1s ease-in-out;
    padding: 0px;

    &.text {
      font-size: 12px;
    }

    &.red {
      color: red;
    }

    &.disabled {
      color: gray;
    }

    &.active {
      color: var(--brand-blue);
    }

    &.i-liked {
      color: var(--brand-blue);
    }

    &:hover {
      transform: scale(1.1) !important;
    }
  }

  fa-icon.msg-options-link {
    font-size: 14px;
  }

  .profile-photo {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    flex-shrink: 0;
  }
}

.reply-container {
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px;
  flex-wrap: nowrap;

  &:last-child {
    border-bottom: 1px solid transparent;
  }
}

.comments-mod {
  .video_splash {
    img {
      height: 60px;
      margin-right: 5px;
    }
    span {
      @extend .brandfont-bold;
      font-size: 12px;
    }
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px 10px 0px 0px;
  }
  .comment-container {
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 30px;
  }
}

.dot-link {
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 0px 10px;
  margin: 10px;
  display: inline-block;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1) !important;
  }
}

.editable-message-span-container {
  min-height: 40px;
  display: flex;
  align-items: end;
  position: relative;

  &.include-border-top {
    border-top: 1px solid #2e2e2e;
    .theme_plainlight & {
      border-top: 1px solid #c7c7c7;
    }

    .force-dark & {
      border-top: 1px solid #2e2e2e !important;
    }
  }

  .send-icon {
    color: var(--brand-blue);
    cursor: pointer;
    font-size: 25px;
  }

  .placeholder {
    display: block;
    position: absolute;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    padding: 5px;

    .theme_plainlight & {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .editable-message-span {
    display: block;
    width: 100%;
    z-index: 999;
    overflow: hidden;
    padding: 12px 15px;
    border-radius: 10px;

    &:empty:not(:focus):before {
      content: attr(data-placeholder);
      color: grey;
      font-style: italic;
    }

    .inline-mention {
      color: #eaeaea;
      background-color: var(--brand-blue);
      font-weight: bold;
      padding: 0px 8px;
      border-radius: 15px;
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 10px;
        margin-right: 5px;
      }
    }

    .inline-article,
    .inline-video,
    .inline-lesson,
    .inline-share {
      font-weight: bold;
      color: var(--brand-blue);
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
      padding: 3px 7px;
      border-radius: 10px;
    }
  }
}

.comment-article-attachment {
  display: flex;
  max-width: 400px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  position: relative;
  color: inherit;
  fa-icon.icon {
    font-size: 30px;
    margin-right: 10px;
  }
  .close-btn {
    position: absolute;
    right: -5px;
    top: -5px;
  }
}

.edited-message {
  color: #4f4f4f;
}

.community-post {
  a.author-name {
    cursor: pointer;
    color: #eaeaea;
    text-decoration: none;

    .theme_plainlight & {
      color: #333;
    }
  }
}

.like-bubble-container {
  position: absolute;
  bottom: 16px;
  left: 0px;
  width: 300px;
  display: flex;
  z-index: 99999;

  .like-bubble-background {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    max-height: 200px;
    overflow: auto;

    .like-bubble {
      margin: 5px 5px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background-size: cover;
      position: relative;
      flex: 0 1 30px;
      list-style: none;
    }
  }
}

.editable-message-span {
  display: block;
  width: 100%;
  z-index: 999;
  overflow: hidden;
  padding: 5px;

  &.input-style {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
    border-radius: 5px;
  }
}

.discussion-files-view,
.chat-container {
  ul li {
    list-style-type: none;
  }
  .inline-mention {
    border: 1px solid white;
    font-weight: bold;
    padding: 0px 8px;
    border-radius: 15px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
      border-radius: 10px;
      margin-right: 5px;
    }
  }
}
.comment-container {
  .list-inline {
    margin-bottom: 0px;
  }
}
.waveform-inline-comment-msg,
.comment-container .main-post-text,
.comment-container .reply-container .text {
  display: inline-block;
  padding: 10px !important;
  background-color: #232323;
  border-radius: 10px;
  margin: 5px 0px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

  .theme_plainlight & {
    background-color: #eaeaea;
  }
}

.comment-container.force-dark .main-post-text,
.comment-container.force-dark .reply-container .text {
  background-color: #232323 !important;
}

.beta-manager-options {
  @media screen and (max-width: 980px) {
    text-align: center;
  }
  .options-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 980px) {
      flex-wrap: wrap;

      div {
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
      }
      .status-text {
        display: none !important;
      }
    }
  }
}

.send-btn-container {
  margin-right: 5px;
}
