/**
 * Element tag overrides
 */
@import url("https://fonts.googleapis.com/css?family=Lato");

body {
  height: 100%;
  padding: 0;
  margin: 0;
  letter-spacing: -0.025em;
  -webkit-font-smoothing: antialiased;
  background-color: #333;
  background-size: cover;
  background-position: Center;
}

.nowrap {
  min-width: 100px;
}

body.modal-open {
  overflow: hidden;
  .sphere-app-content {
    display: block;
    filter: blur(30px);
    height: 100%;
  }

  .prs-global-header {
    filter: blur(0.5rem);
  }
}

body.video-open {
  overflow: hidden;
}

body.nav-overlay-open {
  overflow: hidden;
}

.blurred {
  filter: blur(30px);
}

// body * {
//   font-family: 'Lato', 'Open Sans', 'Helvetica Neue Light', 'Helvetica Light',
//     'Arial Light', Arial, sans-serif;
// }

.t-14 {
  font-size: 14px;
}
.t-16 {
  font-size: 16px;
  line-height: 25px;
}
.t-18 {
  font-size: 18px;
}
.t-20 {
  font-size: 20px;
}
.t-25 {
  font-size: 25px;
}
.t-30 {
  font-size: 30px;
}
.t-40 {
  font-size: 40px;
  line-height: 48px;
}
.t-50 {
  font-size: 50px;
  line-height: 58px;
}
.t-60 {
  font-size: 60px;
  line-height: 70px;
}
.t-70 {
  font-size: 70px;
  line-height: 80px;
}
.t-100 {
  font-size: 100px;
  line-height: 120px;
}
.t-150 {
  font-size: 150px;
  line-height: 180px;
}

button {
  outline: none;

  &:active {
    border: none;
    outline: none;
  }
}

.text-shadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

app-login {
  width: 100%;
}

hr {
  background-color: rgba(255, 255, 255, 0.2);

  &.big {
    margin: 50px 0px;
  }
}

.border-radius5 {
  border-radius: 5px;
}
.border-radius10 {
  border-radius: 10px;
}
.border-radius15 {
  border-radius: 15px;
}
.border-radius20 {
  border-radius: 20px;
}
.border-radius25 {
  border-radius: 25px;
}
.border-radius30 {
  border-radius: 30px;
}

.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.trans-btn {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 0px 10px;
  line-height: 30px;
  color: #eaeaea;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  transform: scale(1, 1);
  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.underline {
  text-decoration: underline;
}

.cloud_only_badge {
  background-color: var(--brand-blue);
  border-radius: 20px;
  color: #eaeaea;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
}

$break-small: 767px;
$break-fullwidth: 575px;
$break-tiny: 460px;
.page-nav-header {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  // border-bottom: 1px solid $transBG;
  padding-bottom: 10px;
  align-items: center;
  margin-top: 10px;

  .title-pills-container {
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 2rem;
    @extend .brandfont-black;
  }

  h2 {
    @extend .brandfont-black;
  }

  &.no-margin-bottom {
    margin-bottom: 0px;
  }

  .cloud-app & {
    // justify-content: center;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0px;
  }

  &.has-cover-photo {
    background-size: cover;
    background-position: center;
    padding: 20px 10px 200px 10px;
    border-bottom: none;
  }

  li.nav-item {
    @media screen and (max-width: $break-small) {
      font-size: 12px;
      font-weight: bold;
    }

    a.nav-link {
      @media screen and (max-width: $break-small) {
        padding: 5px 10px;
      }
    }
  }

  .nav-pills {
    .cloud-app & {
      font-size: 13px;
      margin-left: 10px;
    }

    @media screen and (max-width: $break-tiny) {
      flex-direction: column;
    }
  }

  &.horizontal-stack {
    justify-content: unset;

    @media screen and (max-width: $break-small) {
      justify-content: space-between;
    }

    .nav-pills {
      margin-left: 20px;

      @media screen and (max-width: $break-tiny) {
        flex-direction: column;
        text-align: center;
      }
    }
  }

  &.no-pills {
    h4 {
      align-self: center;
      margin-bottom: 0px;
    }
  }

  .buttons {
    align-self: center;
    white-space: nowrap;

    .circle-icon-btn {
      margin-left: 10px;
    }
  }
}

span.link,
a.link,
button.link {
  color: var(--brand-blue);
  cursor: pointer;
  border: none;
  background-color: transparent;
  .ng-fa-icon {
    color: var(--brand-blue);
  }
}

.muted-delete {
  color: red;
  font-size: 15px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid red;
  }
}

.nav-pills {
  cursor: pointer;
  // color: #eaeaea;
  transition: all 0.1s ease-in-out;

  // &:hover {
  //   color: #eaeaea;
  // }

  &.center {
    justify-content: center;
  }

  .nav-link {
    transition: all 0.1s ease-in-out;
    background-color: transparent;
    margin-right: 5px;
    // border-bottom: 2px solid transparent;
    border-radius: 0px;
    color: #eaeaea;
    white-space: nowrap;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;

    .theme_plainlight & {
      color: #333;
    }

    &:hover {
      // border-bottom: 2px solid var(--brand-blue);
      // color: var(--brand-blue);
      background-color: var(--brand-gray);
    }
    &.active {
      opacity: 0.9;
      // border-bottom: 2px solid var(--brand-blue);
      // background-color: transparent;
      // color: var(--brand-blue);
      background-color: var(--brand-blue);
      color: white;

      &:hover {
        opacity: 1;
      }
    }

    .cloud-app & {
      &.orange {
        background: $orange;
      }
      &.active {
        background: var(--brand-blue);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
        border: 1px solid #0000008c;
      }
    }
  }
}

.section-loader {
  width: 100%;
  text-align: center;
  color: #eaeaea;
  fa-icon {
    font-size: 30px;
  }
}

.modal-loader {
  text-align: center;
  fa-icon {
    font-size: 30px;
  }
}

.mono {
  font-family: "Helvetica Now", sans-serif;
}

.text-sm {
  font-size: 12px !important;
}

.text-md {
  font-size: 16px;
}

.text-lg {
  font-size: 20px;
}

.text-xl {
  font-size: 25px;
}

.btn {
  transition: all 0.1s ease-in-out !important;

  &:hover {
    transform: scale(1.01, 1.01);
  }
}

.sidebar-btn {
  margin: 0px 0px 5px 0px;
  font-weight: bold;
  text-align: left;
  background: none;
  border: none;
  font-size: 12px;
  padding: 0px 0px 5px 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid transparent;
  color: #eaeaea;

  .theme_plainlight & {
    color: #333;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid var(--brand-blue); /* Customize the outline style as needed */
  }

  fa-icon {
    opacity: 0;
  }

  &:hover {
    color: var(--brand-blue);
    border-bottom: 1px solid var(--brand-blue);

    fa-icon {
      opacity: 1;
    }
  }
}

.small-btn {
  // padding: 4px 9px;
  // border-radius: 30px;
  margin: 5px 0px;
  font-size: 12px;
  display: inline-block;
  background-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #eaeaea;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.1s ease-in-out !important;
  padding: 0px 5px 0px 0px;

  fa-icon {
    transition: all 0.1s ease-in-out !important;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid var(--brand-blue); /* Customize the outline style as needed */
  }

  .theme_plainlight & {
    // border: 1px solid #333;
    color: #333;
  }

  &.force-light {
    color: white !important;
    // border: 1px solid white !important;
  }

  &:active {
    // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
    color: #eaeaea;
  }

  &:focus {
    // outline: none !important;
  }

  &.med {
    font-size: 15px;
    font-weight: bold;
  }

  &.gradient {
    background: linear-gradient(180deg, rgba(2, 0, 36, 0.7) 1000%, rgba(14, 14, 68, 0.7) 35%, rgba(0, 120, 144, 0.7) 0%);
    color: #eaeaea;
  }

  &.block {
    display: block;
  }

  &:hover {
    // border: 1px solid var(--brand-blue);;
    // background: var(--brand-blue);;
    text-decoration: none;
    color: var(--brand-blue) !important;

    fa-icon {
      color: var(--brand-blue) !important;
    }
  }

  &.big {
    padding: 8px 10px;
    border-radius: 30px;
    font-size: 20px;

    &:hover {
      transform: scale(1.02, 1.02);
      text-decoration: none;
    }
  }

  &:not(.info) {
    cursor: pointer;
  }

  &.info,
  &.blank {
    border: 1px solid $transBG;
    background-color: transparent;

    &:hover {
      color: var(--brand-blue);
    }
  }

  &.blue {
    color: var(--brand-blue);
  }

  &.green {
    color: $green;
  }

  &.red {
    color: $pink;
  }

  &.orange {
    color: $orange;
  }

  &.pink {
    color: #ff2ea1bb;
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &.no-margin {
    margin: 0px 0px 0px 0px;
  }

  &.m-r-5 {
    margin-right: 5px !important;
  }
}

.stars {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .trans-star {
    position: absolute;
    top: 0px;
    left: 0px;

    .hide {
      opacity: 0;
    }

    fa-icon {
      color: #8f8f8f;
    }
  }

  .gold-star {
    overflow: hidden;
    height: 20px;
    white-space: nowrap;

    fa-icon {
      color: gold;
      stroke: #000;
      stroke-width: 12px;
    }
  }

  &.lg {
    font-size: 50px;
    height: 80px;

    .gold-star {
      height: 80px;
      font-size: 50px;
    }
  }
}

.pointer {
  cursor: pointer;
}

.horizontal-tile-list {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;

  myp-video-grid-item {
    > div {
      min-width: 250px;
      padding: 0px 40px 20px 0px;
    }
  }
}

ul.grid-container {
  padding-left: 0px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  &.wider {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  &.pad-10 {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  &.pad-20 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  &.pad-40 {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  &.even-rows {
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 400px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.prod-item:hover + .prod-item {
  border-top: 1px solid transparent;
}

.prod-item {
  padding: 10px 0px;
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:first-child {
    border-top: 1px solid transparent;
  }

  &:hover {
    background: #232323;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-top: 1px solid transparent;

    .theme_plainlight & {
      background: #f5f5f5;
    }
  }

  .prod-img {
    width: 60px;
    margin: 20px 10px 20px;
    display: inline-block;
    align-self: center;
    transition: all 0.1s ease-in-out;
    transform: scale(1, 1);
    flex-shrink: 0;

    img {
      width: 100%;
      max-height: 100%;
    }
  }

  .prod-data,
  .product-data {
    align-self: center;
    transition: all 0.1s ease-in-out;
    transform: scale(1, 1);
    margin-left: 10px;

    .title {
      font-size: 17px;
    }

    .extra-info {
      display: block;
      line-height: 17px;
      font-size: 13px;
    }
  }

  .buttons {
    flex-shrink: 0;
    align-self: center;
    margin-left: auto;
    margin-right: 10px;
    display: flex;
    align-items: center;

    @media screen and (max-width: $break-small) {
      flex-direction: column;
      align-items: flex-start;
    }

    fa-icon,
    img {
      @media screen and (max-width: $break-small) {
        margin: 5px;
      }
    }

    .chevron {
      @media screen and (max-width: $break-small) {
        display: none;
      }
    }

    .action {
      width: 30px;
      height: 30px;
      text-align: center;
      border-radius: 15px;
      display: inline-block;
      line-height: 30px;
      margin-right: 15px;

      &.is_favorite {
        background-color: var(--brand-blue);
      }
    }
  }

  &.grid {
  }
}
.shadow-container * {
  text-shadow: 1px 1px 10px black;
}

.border-btn {
  cursor: pointer;
  border-color: #eaeaea;
  border-width: 2px;
  border-radius: 5pt;
  border-style: solid;
  color: #eaeaea;
  font-weight: bold;
  padding: 7pt;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 14pt;
  margin-top: 10pt;
  display: block;
  margin: auto;
  text-align: center;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: #4aa1ff96;
    outline: none;
    text-decoration: none;
    color: #eaeaea;
    transform: scale(1.2, 1.2);
  }
}

.detail-section {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

myp-segmented-bar {
  width: 100%;
}
.view-chooser {
  display: flex;
  justify-content: space-around;
  border-radius: 30px;
  border: 1px solid var(--brand-blue);
  z-index: 99;
  width: 100%;
  overflow: hidden;
  box-shadow: none;
  background: transparent;

  &.no-wrap {
    label {
      white-space: nowrap;
    }
  }

  input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }

  .option {
    color: var(--brand-blue);
    font-size: 12px;
    width: 100%;
    text-align: center;
    padding: 5px 10px;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    @extend .brandfont-bold;

    &.selected {
      background-color: var(--brand-blue);
      color: #eaeaea;
      text-shadow: none;
    }

    @media screen and (max-width: 575px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipses;
    }

    .unread-badge {
      padding: 1px 6px;
      background-color: red;
      color: #eaeaea;
      font-weight: bold;
      font-size: 10px;
      border-radius: 10px;
      position: absolute;
      top: 6px;
      right: 25px;
      width: auto;
      height: auto;
    }
  }
}

.image-viewer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 90%;
    max-height: 90%;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.full-screen-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: var(--brand-blue);
}

.menu-search {
  width: 100%;
  background-color: #232323;
  outline: none;
  border-radius: 18px;
  padding: 5px;
  color: white;
  margin: 10px 0px;
  font-weight: bold;
  border: none;
  &::placeholder {
    color: gray;
  }

  .theme_plainlight & {
    background-color: #eaeaea;
  }
}

.theme-option {
  width: 120px;
  height: 120px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid white;
  margin: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-position: center;
  color: #eaeaea;
  background-size: cover;

  &.auto {
    background-color: black;
    color: #eaeaea;

    .theme_plainlight & {
      background-color: #eaeaea;
      color: black;
    }
  }

  &.selected {
    border: 3px solid var(--brand-blue);
  }
}

.dark-image-bg {
  background-color: #333;
}

.login-container {
  text-align: left;
  height: 100%;
  .signup-reasons {
    margin-top: 30px;
    fa-icon {
      font-size: 50px;
    }
    .reason {
      // display: block;
      // margin-bottom: 30px;
    }
  }

  &.bright {
    background: #1b8efc;
  }

  img {
    width: 200px;
    margin: 0px auto 40px auto;
    display: block;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid transparent;
    font-size: 20px;
    outline: none;
    display: block;
    width: 100%;
    margin: 30px 0px;
    border-radius: 30px;
    color: #eaeaea;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: gray;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: gray;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: gray;
    }

    .theme_plainlight & {
      background-color: #eaeaea;
      color: #333;
    }

    &:focus {
      border: 2px solid var(--brand-blue);
    }
  }

  .error-alert {
    margin-top: 10px;
    background-color: rgba(255, 0, 0, 0.37);
    color: #eaeaea;
    padding: 10px;
    border-radius: 10px;
  }
}

.trans-bg {
  background-color: #232323;

  .theme_plainlight & {
    background-color: #f5f5f5;
  }
}

#videooverlay {
  .trans-bg {
    background-color: #232323 !important;
  }
}

.dark-trans-bg {
  background-color: rgba(0, 0, 0, 0.5);
}

.light-trans-bg {
  background-color: rgba(255, 255, 255, 0.5);
}

.pulse-scale {
  scale: 1;
  animation-name: pulse-scale;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes pulse-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-gold {
  color: gold;
  animation-name: pulse-gold-color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes pulse-gold-color {
  0% {
    color: goldenrod;
    transform: scale(1);
  }
  50% {
    color: gold;
    transform: scale(1.2);
  }
  100% {
    color: goldenrod;
    transform: scale(1);
  }
}

.pulse-green {
  background-color: #222;
  animation-name: pulse-green-color;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  .theme_plainlight & {
    animation-name: pulse-light-green-color;
  }

  &.force-dark {
    animation-name: pulse-green-color !important;
  }
}

@keyframes pulse-green-color {
  0% {
    background-color: #193f1e;
  }
  50% {
    background-color: #00530b;
  }
  100% {
    background-color: #193f1e;
  }
}

@keyframes pulse-light-green-color {
  0% {
    background-color: #ace2b3;
  }
  50% {
    background-color: #74d180;
  }
  100% {
    background-color: #ace2b3;
  }
}

.pulse-blue {
  color: white !important;
  background-color: #222;
  animation-name: pulse-blue-color;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  .theme_plainlight & {
    animation-name: pulse-light-blue-color;
  }

  &.force-dark {
    animation-name: pulse-blue-color !important;
  }
}

@keyframes pulse-blue-color {
  0% {
    background-color: #6691b2;
  }
  50% {
    background-color: #405f77;
  }
  100% {
    background-color: #6691b2;
  }
}

@keyframes pulse-light-blue-color {
  0% {
    background-color: #6691b2;
  }
  50% {
    background-color: #405f77;
  }
  100% {
    background-color: #6691b2;
  }
}
.back-container {
  display: flex;
  align-content: center;
  align-items: center;

  fa-icon.back-btn {
    margin-right: 10px;
  }
}

.generic-profile-photo {
  width: 50px;
  height: 50px;
  border-radius: 25px;

  &.large {
    width: 150px;
    height: 150px;
    border-radius: 100px;
  }
}

.radio-option {
  padding: 5px 8px;
  border-radius: 10px;
  font-weight: bold;
  margin: 5px 5px 5px 0px;
  cursor: pointer;

  &.selected {
    background-color: var(--brand-blue);
    color: #eaeaea;
  }
}

.country-item {
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  transition: all 0.1s ease-in-out;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.generic-file-attachment {
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin: 5px;
  position: relative;
  display: inline-block;

  fa-icon {
    cursor: pointer;
  }
}

.btn-transparent {
  color: white;

  .theme_plainlight & {
    color: #333;
  }

  &:hover {
    color: var(--brand-blue);
  }
}
